import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/Hebrews_Big.jpg"
import Hebrews from "../../../images/Hebrews_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Hebrews"
    SecondText="Check out sermons from our Hebrews sermon series"
  >
    <SEO title="Sermons - Hebrews" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
                        <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="The Closing Argument"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/the-closing-argument"
        date="July 28th, 2024"
        pastor="Pastor Jackie"
        badge="NEW"
        badgeColor="teal"
      />
                    <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="The Settled Life"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/the-settled-life"
        date="July 21st, 2024"
        pastor="Pastor Andrew"
      />
                <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="The Long Arm of the Lord"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/the-long-arm-of-the-lord"
        date="July 14th, 2024"
        pastor="Pastor Jackie"
      />
            <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Faith"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/faith"
        date="July 7th, 2024"
        pastor="Pastor Chad"
      />
        <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Let Us..."
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/let-us"
        date="June 30th, 2024"
        pastor="Pastor Jackie"

      />
        <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="When Enough Isn't Enough"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/when-enough-isnt-enough"
        date="June 23rd, 2024"
        pastor="Pastor Andrew"
      />
    <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="But Jesus"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/but-jesus"
        date="June 16th, 2024"
        pastor="Pastor Jackie"
      />
     <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Needs and Nostalgia"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/needs-and-nostalgia"
        date="June 9th, 2024"
        pastor="Pastor Tyler"
      />
                          <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="The Problem with Celibate Priests and Celebrity Pastors"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/celibate-priests-celebrity-pastors"
        date="June 2nd, 2024"
        pastor="Pastor Jackie"
      />
                      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Hebrews"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/hebrews-sermon"
        date="May 26th, 2024"
        pastor="Pastor Chad"
      />
                  <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="A Better Priest"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/a-better-priest"
        date="May 19th, 2024"
        pastor="Pastor Jackie"
      />
              <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="A Better Rest"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/a-better-rest"
        date="May 12th, 2024"
        pastor="Pastor Jackie"
      />
          <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="A Better House"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/a-better-house"
        date="May 5th, 2024"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus and Us"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/jesus-and-us"
        date="April 28th, 2024"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="What to do when your grip begins to slip"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/grip-begins-to-slip"
        date="April 21st, 2024"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus is Superior"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/jesus-is-superior"
        date="April 14th, 2024"
        pastor="Pastor Jackie"
        badge="NEW"
        badgeColor="teal"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus is Better"
        sermonSeries="Hebrews"
        sermonLink="/surprise/sermons/jesus-is-better"
        date="April 7th, 2024"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/surprise/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Surprise"
      />
    </Grid>
  </Layout>
)

export default IndexPage
